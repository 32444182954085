<template>
	<seller-profile :mode="constants.SELLER_PROFILE_MODE.PROFILE"></seller-profile>
</template>

<script>
import SellerProfile from '@/components/seller/shop/Profile';
import Constants from '@/variables/constants';

export default {
	components: {
		SellerProfile
	},
	data() {
		return {
			constants: Constants
		}
	},
}
</script>